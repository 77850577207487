// newsletter

@include b(module-newsletter) {
  @include gridle (22 prefix 1 suffix 1 md 24 prefix 0 suffix 0);
}

@include b(newsletter) {
  padding: 3rem 1rem;
  text-align: center;
  border: 3px solid $brand-primary;

  h3 {
    text-transform: uppercase;
  }

  p {
    margin-bottom: 2.5rem;
    font-style: italic;
  }
}

@include b(newsletter-input) {
  @include gridle (22 prefix 1 suffix 1 tb 16 prefix 1 suffix 0 md 18 prefix 1 suffix 0);
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 1rem;

  @include gridle_state (tb) {
    padding-right: 20px;
    margin-bottom: 0;
  }
}


@include b(btn-newsletter) {
  @include gridle (22 prefix 1 suffix 1 tb 6 prefix 0 suffix 1 md 4 prefix 0 suffix 1);
}

input.btn-newsletter {
  padding: 1.425rem .75rem;
}
