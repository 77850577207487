// Typography

// @import url('https://fonts.googleapis.com/css?family=Montserrat:500,600');

$font-family-sans-serif:          "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:               Baskerville, Baskerville Old Face, Hoefler Text, Garamond, Times New Roman, serif !default;
$font-family-monospace:           Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:                $font-family-serif !default;
$text-color:                      $body-text-color !default;

$font-weight-light:               300 !default;
$font-weight-normal:              500 !default;
$font-weight-bold:                600 !default;

$font-size-root:                  16px !default;
$font-size-base:                  1rem !default;
$font-size-xxlg:                  1.875rem !default; // 30px
$font-size-xlg:                   1.5rem !default; // 24px
$font-size-lg:                    1.125rem !default; // 18px
$font-size-sm:                    .875rem !default; // 14px
$font-size-xs:                    .75rem !default; // 12px

$line-height-base:                1.5 !default;
$line-height-lg:                  (4 / 3) !default;
$line-height-sm:                  1.5 !default;
$line-height-computed:            floor(($font-size-base * $line-height-base)) !default; // ~20px

$font-size-h1:                    3.75rem !default; // 40px
$font-size-h2:                    2.25rem !default; // 36px
$font-size-h3:                    1.75rem !default;
$font-size-h4:                    1.5rem !default;
$font-size-h5:                    1.25rem !default;
$font-size-h6:                    1rem !default;

$headings-margin-bottom:          ($spacer / 2) !default;
$headings-font-family:            $font-family-sans-serif !default;
$headings-font-weight:            $font-weight-bold !default;
$headings-line-height:            1.1 !default;
$headings-font-color:             $body-text-color !default;

$link-color:                      $brand-primary !default;
$link-decoration:                 none !default;
$link-hover-color:                darken($link-color, 15%) !default;
$link-hover-decoration:           none !default;

$hr-border-color:                 $grey-lighter !default;
$hr-border-width:                 $border-width !default;

$text-muted:                      $grey-lighter !default;
