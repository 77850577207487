// title links

.home {

  .title-link {
    color: $text-color;
    text-decoration: none;
  }

}
