@include b(page-header) {
  @include gridle (22 prefix 1 suffix 1 md 24 prefix 0 suffix 0);
  text-align: center;

  @include e(title) {
    display: inline-block;
    text-transform: uppercase;

    &:after {
      display: block;
      width: 100%;
      height: 3px;
      margin-top: .5rem;
      margin-left: 0;
      background-color: $brand-primary;
      content: '';

      @include gridle_state (md) {
        width: calc(100% + 5rem);
        margin-left: -2.5rem;
      }
    }
  }
}
