// brand.svg

.brand-clr1 {
  fill: $brand-primary;
}

.brand-clr2 {
  fill: $brand-secondary;
}

// brand

@include b(brand) {
  display: block;
  transition: width .8s, height .8s;

  @include m(header) {
    width: 96px;
    height: 96px;
    margin: 0 auto;

    @include gridle_state (md) {
      width: 128px;
      height: 128px;
    }
  }

  @include m(instagram) {
    display: inline-block;
    width: 90px;
    height: 90px;
    margin: 0 auto;
  }

  @include m(studio) {
    display: inline-block;
    width: 110px;
    height: 131px;
    margin: 0 auto;
  }

  @include m(bridal) {
    display: inline-block;
    width: 110px;
    height: 131px;
    margin: 0 auto;
  }

  @include m(nancy) {
    display: inline-block;
    width: 154px;
    height: 80px;
    margin: 0 auto;
  }
}
