// location

@include b(module-location-map) {
  @include gridle (22 prefix 1 suffix 1 md 12 prefix 0 suffix 0);
  margin-bottom: 1.5rem;

  @include gridle_state (md) {
    margin-bottom: 0;
  }
}

@include b(module-location-address) {
  @include gridle (22 prefix 1 suffix 1 md 12 prefix 0 suffix 0);
}
