// post_tags

.post_tags {
  a {
    display: inline-block;
    padding: .5rem;
    margin: .5rem;
    font-size: 1.5rem;
  }
}
