// button variant

@mixin button-variant($color, $background, $border) {

  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  &:active {

    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 10%);

  }

  &:active {

    background-image: none;

  }

}

// button outline variant

@mixin button-outline-variant($color) {

  color: $color;
  background-image: none;
  background-color: transparent;
  border-color: $color;

  &:hover,
  &:focus,
  &:active {

    color: $white;
    background-color: $color;
    border-color: $color;

  }

  &:active {

    background-image: none;

  }

}

// button sizes

@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {

  padding: $padding-y $padding-x;
  font-size: $font-size;
  border-radius: $border-radius;

}
