// testimonials

.l-front-page-testimonials {
  position: relative;
}

@include b(testimonials-title) {
  display: inline-block;
  position: relative;
  bottom: auto;
  padding-bottom: 2.5rem;
  padding-left: 2.5rem;
  text-transform: uppercase;

  @include gridle_state (sm) {
    padding-left: calc(2.5rem + 52px);
  }

  @include gridle_state (md) {
    position: absolute;
    top: 2px;
    left: 0;
    padding-bottom: 0;
    padding-left: 1.5rem;
  }

  @include gridle_state (md) {
    padding-left: 2.5rem;
  }

  @include gridle_state (lg) {
    padding-left: 3.5rem;
  }

  @include gridle_state (xlg) {
    padding-left: 5rem;
  }

  &:after {
    display: block;
    width: calc(100%);
    height: 3px;
    margin-top: .5rem;
    background-color: $brand-primary;
    content: '';

    @include gridle_state (md) {
      width: calc(100% + .5rem);
    }

    @include gridle_state (lg) {
      width: calc(100% + 1rem);
    }

    @include gridle_state (xlg) {
      width: calc(100% + 4rem);
    }
  }
}

@include b(front-page-testimonials) {
  @include gridle (22 prefix 1 suffix 1 md 15 prefix 9 suffix 0 lg 16 prefix 8 suffix 0);
  position: relative;

  @include e(content) {
    // padding-top: 1.5rem;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    padding-right: 0;
    padding-left: 0;

    // @include gridle_state (tb) {
    //   padding-top: 2rem;
    // }

    // @include gridle_state (lg) {
    //   padding-top: 3rem;
    // }
  }
}
