// Typography

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  margin-bottom: $headings-margin-bottom;
  padding: 0;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-font-color;
}

h1, .h1 {
  font-size: $font-size-h2;

  @include gridle_state (md) {
    font-size: $font-size-h1;
  }
}
h2, .h2 {
  font-size: $font-size-h3;

  @include gridle_state (md) {
    font-size: $font-size-h2;
  }
}
h3, .h3 {
  font-size: $font-size-h3;

  @include gridle_state (md) {
    font-size: $font-size-h2;
  }
}
h4, .h4 {
  font-size: $font-size-h5;

  @include gridle_state (md) {
    font-size: $font-size-h4;
  }
}
h5, .h5 {
  font-size: $font-size-h6;

  @include gridle_state (md) {
    font-size: $font-size-h5;
  }
}
h6, .h6 {
  font-size: $font-size-h6;

  @include gridle_state (md) {}
}

p,
ul {
  margin: 0 0 $font-size-base;
  font-family: inherit;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  line-height: $line-height-lg;
  color: $text-color;

  @include gridle_state (tb) {
    font-size: $font-size-xlg;
    margin-bottom: $font-size-xlg;
  }

  @include gridle_state (lg) {
    font-size: $font-size-xxlg;
    margin-bottom: $font-size-xxlg;
  }

  a {
    &:hover {
      border-bottom: 1px solid $link-hover-color;
    }
  }
}

// li {
//   margin-bottom: 0;
// }

strong {
  font-weight: $font-weight-bold;
}

small, .small {
  font-size: $font-size-sm;
}

span {
  color: $brand-primary;
}

a,
.link {
  display: inline;
  color: $link-color;
  text-decoration: $link-decoration;
  cursor: pointer;

  // &:hover {
  //   color: $link-hover-color;
  //   text-decoration: $link-hover-decoration;
  //   border-bottom: 1px solid $link-hover-color;
  // }
}

.time,
.tags {
  margin-top: $font-size-sm;
  margin-bottom: $font-size-sm;
  font-size: $font-size-base;
}

