@include b(social-menu) {
  @extend %center-h;
  display: block;
  padding: 0;
  margin: 0 auto 2rem;
  font-size: 0;

  @include e(item) {
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle;

    &:last-child {margin-right: 0;}

    a {
      display: block;
      width: 24px;
      height: 24px;

      @include gridle_state (sm) {
        width: 38px;
        height: 38px;
      }
    }

    a.facebook {

      .social-icon {
        .circle {
          fill: none;
          stroke: $social-facebook;
          stroke-width: 2;
        }
        .icon {
          fill: $social-facebook;
        }
        &:hover {
          .circle {
            stroke: $social-facebook;
          }
          .icon {
            fill: $social-facebook;
          }
        }
      }

    }

    a.twitter {

      .social-icon {
        .circle {
          fill: none;
          stroke: $social-twitter;
          stroke-width: 2;
        }
        .icon {
          fill: $social-twitter;
        }
        &:hover {
          .circle {
            stroke: $social-twitter;
          }
          .icon {
            fill: $social-twitter;
          }
        }
      }

    }

    a.instagram {

      .social-icon {
        .circle {
          fill: none;
          stroke: $social-instagram;
          stroke-width: 2;
        }
        .icon {
          fill: $social-instagram;
        }
        &:hover {
          .circle {
            stroke: $social-instagram;
          }
          .icon {
            fill: $social-instagram;
          }
        }
      }

    }

    a.pinterest {

      .social-icon {
        .circle {
          fill: none;
          stroke: $social-pinterest;
          stroke-width: 2;
        }
        .icon {
          fill: $social-pinterest;
        }
        &:hover {
          .circle {
            stroke: $social-pinterest;
          }
          .icon {
            fill: $social-pinterest
          }
        }
      }

    }

    a.google {

      .social-icon {
        .circle {
          fill: none;
          stroke: $social-rss;
          stroke-width: 2;
        }
        .icon {
          fill: $social-rss;
        }
        &:hover {
          .circle {
            stroke: $social-rss;
          }
          .icon {
            fill: $social-rss;
          }
        }
      }

    }

    a.youtube {

      .social-icon {
        .circle {
          fill: none;
          stroke: $social-rss;
          stroke-width: 2;
        }
        .icon {
          fill: $social-rss;
        }
        &:hover {
          .circle {
            stroke: $social-rss;
          }
          .icon {
            fill: $social-rss;
          }
        }
      }

    }

    a.rss {

      .social-icon {
        .circle {
          fill: none;
          stroke: $social-rss;
          stroke-width: 2;
        }
        .icon {
          fill: $social-rss;
        }
        &:hover {
          .circle {
            stroke: $social-rss;
          }
          .icon {
            fill: $social-rss;
          }
        }
      }

    }

  }

}
