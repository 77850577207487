nav.nav-primary {
  width: 100%;

  ul {
    display: none;
    padding: 1rem 0;
    margin: 0;
    margin-top: 2rem;
    list-style: none;
    background-color: $nav-bg-color;

    @include gridle_state (tb) {
      margin-top: 4rem;
    }

    li {
      position: relative;

      a {
        display: block;
        padding: .5rem 2rem;
        font-family: $nav-font-family;
        font-size: 1.5rem;
        font-weight: $nav-font-weight;
        line-height: 1.75rem;
        color: $nav-font-color;
        text-align: right;
        text-decoration: none;
        text-transform: uppercase;
        background-color: $nav-bg-color;

        @include gridle_state (tb) {
          font-size: 1.75rem;
          line-height: 2.125rem;
        }

        @include gridle_state (md) {
          font-size: 2.375rem;
          line-height: 2.75rem;
        }

        &:hover {
          color: $nav-font-hover-color;
          background-color: $nav-bg-hover-color;
        }

        &:not(:only-child):after {
          padding-left: 4px;
          content: ' ▾';
        }

        &.active {
          color: $nav-font-hover-color;
          background-color: $nav-bg-hover-color;
        }
      }
      ul.sub-menu {
        position: static;
        z-index: 1;
        display: none;
        padding: 0;
        margin-top: 0;

        li {
          min-width: 190px;

          a {
            z-index: 1;
            padding-right: 3.5rem;
            font-size: 1.5rem;
            line-height: 1.75rem;
            color: $white;
            background-color: $nav-bg-hover-color;

            @include gridle_state (tb) {
              padding-right: 3.875rem;
              font-size: 1.75rem;
              line-height: 2.125rem;
            }

            @include gridle_state (md) {
              padding-right: 4.25rem;
              font-size: 2rem;
              line-height: 2.25rem;
            }
          }
        }
      }
    }
  }
}
