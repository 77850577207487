// forms

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $white;
  transition: opacity 250ms ease-in-out;
}
:focus::-webkit-input-placeholder {
  opacity: 0.5;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $white;
  transition: opacity 250ms ease-in-out;
}
:focus:-ms-input-placeholder {
  opacity: 0.5;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $white;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}
:focus::-moz-placeholder {
  opacity: 0.5;
}
:-moz-placeholder { /* Firefox 4 - 18 */
  color: $white;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}
:focus:-moz-placeholder {
  opacity: 0.5;
}

// responsive placeholder text
// add elipses on overflow

input[placeholder] { text-overflow: ellipsis; }
::-moz-placeholder { text-overflow: ellipsis; } /* firefox 19+ */
input:-moz-placeholder { text-overflow: ellipsis; }


.gform_body ul {
  padding: 0;
  margin: 0;
}


// checkbox

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  color: $text-color;
}

input[type="checkbox"] + label span {
  display: inline-block;
  width: 38px;
  height: 38px;
  margin: -2px 10px 0 0;
  vertical-align: middle;
  cursor: pointer;
  background: url('../images/check_radio_sheet.png') left top no-repeat;
}

input[type="checkbox"]:checked + label span {
  background: url('../images/check_radio_sheet.png') -38px top no-repeat;
}

// radio

input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  color: $text-color;
}

input[type="radio"] + label span {
  display: inline-block;
  width: 38px;
  height: 38px;
  margin: -2px 10px 0 0;
  vertical-align: middle;
  background: url('../images/check_radio_sheet.png') -76px top no-repeat;
  cursor: pointer;
}

input[type="radio"]:checked + label span {
  background: url('../images/check_radio_sheet.png') -114px top no-repeat;
}

.name_first {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-bottom: 1.5rem;
  float: none;

  @include gridle_state (tb) {
    width: 49%;
    float: left;
    margin-right: 2%;
    margin-bottom: 0;
  }
}

.name_last {

  width: 100%;
  float: none;

  @include gridle_state (tb) {
    width: 49%;
    float: left;
    margin-right: 0;
  }
}
