// instagram

@include b(module-instagram) {
  @include gridle (22 prefix 1 suffix 1 md 24 prefix 0 suffix 0);
  text-align: center;

  @include e(title) {
    margin-bottom: 1rem;
    text-transform: uppercase;

    @include gridle_state (tb) {
      margin-bottom: 2rem;
    }
  }
}

@include b(instagram-gallery) {
  text-align: center;
  border: 3px solid $brand-primary;

  @include e(header) {
    padding: 40px 1rem 0;
    text-align: center;
    white-space: nowrap;
  }

  @include e(footer) {
    padding: 0 1rem 40px;
    text-align: center;
  }
}

// #sb_instagram {
//   padding-bottom: 0 !important;
// }

#sb_instagram {
  padding: 3rem 2rem 3.75rem; // !important
}

// instagram.svg

.instagram-clr1 {
  fill: $brand-secondary;
}

@include b(instagram) {
  display: block;
  transition: width .8s, height .8s;

  @include m(gallery) {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 1rem;
  }
}

@include b(hashtag) {
  display: block;
  margin: 1rem .5rem 0;
  font-size: inherit;
  transition: width .8s, height .8s;

  @include gridle_state (tb) {
    display: inline-block;
  }

  h4 {
    font-size: 1rem;

    @include gridle_state (sm) {
      font-size: 1.25rem;
    }
  }
}

@include b(fake-gallery) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0;
  list-style: none;
  list-style-type: none;

  @include e(item) {
    width: 100%;
    padding: 20px;

    @include gridle_state (sm) {
      width: 50%;
    }

    @include gridle_state (tb) {
      width: 25%;
    }
  }
}
