.l-footer {
  background-color: $grey;
}

footer {
  a {
    color: $text-color;
  }
}

@include b(footer-left) {
  @include gridle (22 prefix 1 suffix 1 tb 12 prefix 0 suffix 0);
  text-align: right;

  @include e(contact) {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-style: italic;
    line-height: 2rem;

    @include gridle_state (tb) {
      margin-bottom: 0;
      font-size: 1.75rem;
      line-height: 3rem;
    }

    @include gridle_state (md) {
      font-size: 2.125rem;
      line-height: 4rem;
    }
  }
}

@include b(footer-right) {
  @include gridle (22 prefix 1 suffix 1 tb 12 prefix 0 suffix 0);
  border-left: none;
  text-align: right;

  @include gridle_state (tb) {
    text-align: left;
    border-left: 2px solid $white;
  }

  @include e(address) {
    margin: 0;
    font-size: 1.5rem;
    font-style: italic;

    @include gridle_state (tb) {
      font-size: 2em;
    }
  }
}
