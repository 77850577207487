// highlights

@include b(front-page-highlights) {
  @include gridle (22 prefix 1 suffix 1 md 12 prefix 0 suffix 0);

  margin-bottom: 1.25rem;

  @include gridle_state (tb) {
    margin-bottom: 2.5rem;

    &:nth-child(4) {
      margin-bottom: 0;
    }
  }

  @include gridle_state (md) {

    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 0;
    }
  }

  figure {
    margin: 0;
  }
}


/* Common style */
.front-page-highlights figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  background-color: $brand-primary;
  text-align: center;
  cursor: pointer;
}

.front-page-highlights figure img {
  position: relative;
  display: block;
  height: auto;
  width: 100%;
}

.front-page-highlights figure figcaption {
  padding: 2rem;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.front-page-highlights figure figcaption::before,
.front-page-highlights figure figcaption::after {
  pointer-events: none;
}

.front-page-highlights figure figcaption,
.front-page-highlights figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.front-page-highlights figure figcaption > a {
  z-index: 1000;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.front-page-highlights figure h2 {
}

.front-page-highlights figure h2 span {
}

.front-page-highlights figure h2,
.front-page-highlights figure p {
  margin: 0;
}

.front-page-highlights figure p {
}



/* Effect style */
figure.hover-effect {
  background-color: $brand-primary;
}

figure.hover-effect img {
  opacity: 1;
  transition: opacity .6s;
}

figure.hover-effect:hover img {
  opacity: 0.3;
}

figure.hover-effect figcaption::before,
figure.hover-effect figcaption::after {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  content: '';
  opacity: 0;
  transition: opacity .6s, transform .6s;
}

figure.hover-effect figcaption::before {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0,1);
  -ms-transform: scale(0,1);
  transform: scale(0,1);
}

figure.hover-effect figcaption::after {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1,0);
  -ms-transform: scale(1,0);
  transform: scale(1,0);
}

figure.hover-effect h2 {
  position: absolute;
  width: calc(100% - 4rem);
  bottom: 90px;
  left: 50%;
  font-size: 2rem;
  color: $white;
  text-transform: uppercase;
  transition: transform .6s;
  -webkit-transform: translate(-50%,30px);
  -ms-transform: translate(-50%,30px);
  transform: translate(-50%,30px);
}

figure.hover-effect:hover h2 {
  opacity: 1;
  -webkit-transform: translate(-50%,0);
  -ms-transform: translate(-50%,0);
  transform: translate(-50%,0);
}

figure.hover-effect p {
  position: absolute;
  width: 100%;
  bottom: 45px;
  left: 50%;
  font-size: 2rem;
  color: $white;
  font-style: italic;
  opacity: 0;
  transition: opacity .6s, transform .6s;
  -webkit-transition-delay: .3s;
  -ms-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-transform: translate(-50%,30px);
  -ms-transform: translate(-50%,30px);
  transform: translate(-50%,30px);
}

figure.hover-effect:hover p {
  opacity: 1;
  -webkit-transform: translate(-50%,0);
  -ms-transform: translate(-50%,0);
  transform: translate(-50%,0);
}

figure.hover-effect:hover figcaption::before,
figure.hover-effect:hover figcaption::after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
