$form-font-family:              $font-family-sans-serif !default;
$form-font-size:                $font-size-lg !default;
$form-line-height:              $form-font-size * 1.5 !default;
$form-space:                    1.5rem;

$input-label-font-size:         $font-size-base !default;
$input-label-color:             $text-color !default;
$input-label-font-weight:       $font-weight-light !default;

$input-bg:                      $grey !default;
$input-border:                  $grey !default;
$input-border-focus:            $brand-primary !default;
$input-error:                   $brand-danger !default;
$input-shadow:                  none !default;
$input-transition:              all .3s ease-in-out !default;

$input-font-size:               $font-size-base !default;
$input-color:                   $white !default;
$input-font-weight:             $font-weight-bold !default;

$form-required:                 $brand-danger !default;
$form-muted:                    #858585 !default;
