// clients

@include b(module-clients) {
  @include gridle (22 prefix 1 suffix 1 md 24 prefix 0 suffix 0);
  text-align: center;

  @include m(narrow) {
    @include gridle (22 prefix 1 suffix 1 md 18 prefix 3 suffix 3);
  }

  @include e(title) {
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
}

@include b(client-list) {
  display: block;
  padding: 1.5rem;
  border: 3px solid $brand-primary;
  font-size: 0;
  text-align: center;
  list-style: none;
  list-style-type: none;

  @include e(item) {
    display: inline-block;
    padding: 1rem;
    white-space: nowrap;
    list-style: none;
    list-style-type: none;

    @include gridle_state (md) {
      padding: 2rem;
    }

    .replaced-svg {
      width: 100%;
      max-width: 215px;

      @include gridle_state (mb) {
        max-width: 100%;
      }
    }
  }
}
