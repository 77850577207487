// spacing

@include b(l-t-padding) {
  padding-top: 2rem;

  @include gridle_state (tb) {
    padding-top: 4rem;
  }

  @include gridle_state (md) {
    padding-top: 5rem;
  }

  @include gridle_state (lg) {
    padding-top: 6rem;
  }

  @include m(min) {
    padding-top: 2rem;

    @include gridle_state (tb) {
      padding-top: 4rem;
    }
  }
}

@include b(l-b-padding) {
  padding-bottom: 2rem;

  @include gridle_state (tb) {
    padding-bottom: 4rem;
  }

  @include gridle_state (md) {
    padding-bottom: 5rem;
  }

  @include gridle_state (lg) {
    padding-bottom: 6rem;
  }

  @include m(min) {
    padding-bottom: 2rem;

    @include gridle_state (tb) {
      padding-bottom: 4rem;
    }
  }
}

// common

.wrap {
  width: 100%;
}
