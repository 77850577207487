// profile

@include b(section-half) {
  @include gridle (22 prefix 1 suffix 1 md 12 prefix 0 suffix 0);
  position: relative;

  @include e(figure) {
    position: relative;
    margin: 0;

    img {
      width: 100%;
      height: auto;
    }

    figcaption {
      position: absolute;
      bottom: 2rem;
      left: 2rem;
      z-index: 1000;

      .meet-nancy {
        padding-left: 2.5rem;
        font-family: $font-family-sans-serif;
        font-size: 1.5rem;
        color: $white;
        text-transform: uppercase;

        &:before {
          position: absolute;
          top: 2px;
          left: 0;
          display: block;
          width: 2rem;
          height: 2rem;
          background-image: url('../images/play.svg');
          background-size: 2rem 2rem;
          content: '';
        }
      }
    }
  }

  @include e(title) {
    display: inline-block;
    padding-left: 2.5rem;
    margin-top: calc(2rem + 3px);
    text-transform: uppercase;

    @include gridle_state (md) {
      padding-left: 0;
      margin-top: 0;
    }

    &:after {
      display: block;
      width: calc(100% + 2.5rem);
      height: 3px;
      margin-top: .5rem;
      margin-left: -2.5rem;
      background-color: $brand-primary;
      content: '';

      @include gridle_state (md) {
        width: calc(100% + 5rem);
        margin-left: -5rem;
      }
    }
  }

  @include e(content) {
    padding-top: 1.5rem;
    // padding-right: 2.5rem;
    // padding-left: 2.5rem;
    padding-right: 0;
    padding-left: 0;

    @include gridle_state (tb) {
      padding-top: 2rem;
    }

    @include gridle_state (lg) {
      padding-top: 3rem;
    }

    @include m(right) {

      @include gridle_state (md) {
        padding-left: 0;
      }

      .blockquote {
        @include gridle_state (md) {
          padding-left: 0;
        }
      }
    }

    @include m(left) {

      @include gridle_state (md) {
        padding-right: 0;
      }

      .blockquote {
        @include gridle_state (md) {
          padding-right: 0;
        }
      }
    }
  }
}

.section-half-image {
  position: relative;
  z-index: 1;
}
.section-half-content {
  position: relative;
  z-index: 2;
}

