// navigation

// $nav-font-family:               inherit !default;
// $nav-font-size:                 $font-size-base !default;
// $nav-font-color:                $body-text-color !default;
// $nav-font-hover-color:          $brand-primary !default;
// $nav-font-weight:               $font-weight-bold !default;
// $nav-bg-color:                  $white !default;
// $nav-bg-hover-color:            $grey-lighter !default;

// $nav-dd-font-size:              $font-size-sm !default;
// $nav-dd-font-color:             $body-text-color !default;
// $nav-dd-font-hover-color:       $brand-primary !default;
// $nav-dd-bg-color:               $white !default;
// $nav-dd-bg-hover-color:         $body-bg-color !default;
// $nav-dd-border-bottom:          $border-width solid $grey-lighter;

// Toggle Variables

$toggle-color: $text-color;

// Navigation Variables

$nav-font-family: $font-family-sans-serif;
$nav-font-weight: 600;
$nav-font-color: $white;
$nav-font-hover-color: $white;
$nav-bg-color: $brand-primary;
$nav-bg-hover-color: $text-color;
