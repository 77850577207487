// archive nav

nav.archive-nav {
  @include gridle (22 prefix 1 suffix 1 md 24 prefix 0 suffix 0);

  .next-posts, .previous-posts {
    a {
      @extend .btn;
      @extend .btn--btn-primary;
    }
  }
}

.blog {
  nav.archive-nav {
    @include gridle (22 prefix 1 suffix 1);
  }
}
