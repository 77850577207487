// testimonials

@include b(testimonials-list) {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-type: none;

  li {

    &:nth-child(odd) {

      .section-half-image {
        float: none;
      }

      .section-half__content {

        @include gridle_state (md) {
          padding-right: 0;
          // padding-left: 2.5rem;
        }
      }
    }

    &:nth-child(even) {

      .section-half__content {

        @include gridle_state (md) {
          // padding-right: 2.5rem;
          padding-left: 0;
        }
      }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
