// blockquote.svg

@include b(blockquote) {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  margin: 0;
  margin-bottom: $font-size-lg;

  @include gridle_state (tb) {
    margin-bottom: $font-size-xlg;
  }

  @include gridle_state (lg) {
    margin-bottom: $font-size-xxlg;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include gridle_state (tb) {
    padding-left: 4.75rem;
    padding-right: 4.75rem;
  }

  @include m(border) {
    padding: 1rem;
    // added padding
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    // end added padding
    text-align: center;
    border: 3px solid $grey;
    border-radius: .5rem;

    @include gridle_state (tb) {
      // padding: 1.5rem 2rem;
      padding: 1.5rem 4.75rem;
    }

    p {
      font-style: italic;

      cite {
        font-size: 1rem;
        font-style: normal;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  p {
    position: relative;

    &:first-of-type {

      &:before {
        position: absolute;
        top: -10px;
        // left: -2.5rem;
        left: -2.125rem;
        display: block;
        width: 30px;
        height: 24px;
        background-image: url('../images/quote--open.svg');
        background-size: 30px 24px;
        content: '';

        @include gridle_state (tb) {
          // top: -20px;
          top: -16px;
          // left: -4.5rem;
          left: -4.125rem;
          width: 59px;
          height: 46px;
          background-size: 59px 46px;
        }
      }

    }

    &:last-of-type {

      &:after {
        position: absolute;
        // right: -2.5rem;
        right: -2.125rem;
        bottom: -10px;
        display: block;
        width: 30px;
        height: 24px;
        background-image: url('../images/quote--close.svg');
        background-size: 30px 24px;
        content: '';

        @include gridle_state (tb) {
          // right: -4.5rem;
          right: -4.125rem;
          // bottom: -20px;
          bottom: -16px;
          width: 59px;
          height: 46px;
          background-size: 59px 46px;
        }
      }
    }
  }
}
