/* Start: Recommended Isotope styles */

/**** Isotope Filtering ****/
.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

/**** Isotope CSS3 transitions ****/

.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: .9s;
     -moz-transition-duration: .9s;
      -ms-transition-duration: .9s;
       -o-transition-duration: .9s;
          transition-duration: .9s;
}

.isotope {
  -webkit-transition-property: height, width;
     -moz-transition-property: height, width;
      -ms-transition-property: height, width;
       -o-transition-property: height, width;
          transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
     -moz-transition-property:    -moz-transform, opacity;
      -ms-transition-property:     -ms-transform, opacity;
       -o-transition-property:      -o-transform, opacity;
          transition-property:         transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
     -moz-transition-duration: 0s;
      -ms-transition-duration: 0s;
       -o-transition-duration: 0s;
          transition-duration: 0s;
}

/* End: Recommended Isotope styles */

@include b(filters) {
  @include gridle (22 prefix 1 suffix 1 md 24 prefix 0 suffix 0);
  font-size: 0;
  text-align: center;
  list-style: none;
  list-style-type: none;

  @include e(item) {
    display: inline-block;
    margin-right: 1.5rem;
    line-height: 2.5rem;

    &:last-child {
      margin-right: 0;
    }

    a {
      padding: .5rem;
      font-family: $font-family-sans-serif;
      font-size: 1rem;
      font-weight: $font-weight-bold;
      color: $text-color;
      text-transform: uppercase;

      &:hover {
        text-decoration: none;
        border-bottom: 3px solid $brand-primary;
      }

      &.selected {
        border-bottom: 3px solid $brand-primary;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}

.isotope-list {
  @include gridle (22 prefix 1 suffix 1 md 24 prefix 0 suffix 0);
  padding-right: 0;
  padding-left: 0;
  text-align: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
  }

  .item {
    @include gridle (22 prefix 1 suffix 1 tb 12 prefix 0 suffix 0 md 8 prefix 0 suffix 0);
    margin-bottom: 4rem;

    img {
      margin-bottom: 1.5rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    p.excerpt {
      font-size: 1.5rem;
    }
  }
}
