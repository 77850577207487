@include b(legal) {
  @include gridle (22 prefix 1 suffix 1 md 24 prefix 0 suffix 0);
  text-align: center;
}

@include b(legal-nav) {
  padding: 0;
  margin: 2rem 0 1rem;
  font-size: 0;
  text-align: center;

  li {
    display: inline-block;
    margin-right: .5rem;
    list-style: none;
    list-style-type: none;

    &:last-child {
      margin-right: 0;
    }

    a {
      padding: .5rem;
      font-family: $font-family-sans-serif;
      font-size: 1rem;
      font-weight: $font-weight-bold;
      color: $text-color;
      text-transform: uppercase;
    }
  }
}
