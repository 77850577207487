// courses

// course dates

@include b(course-info) {
  @include gridle (22 prefix 1 suffix 1);
  padding-right: 0;
  padding-left: 0;
  text-align: center;

  @include e(title) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-bottom: 1rem;
    text-transform: uppercase;

    @include gridle_state (tb) {
      margin-bottom: 2rem;
    }
  }

  @include e(content) {
    padding: 1rem;
    border: 3px solid $brand-primary;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $brand-primary;
      text-transform: uppercase;
    }
  }
}

@include b(course-list) {
  padding: 0;
  margin: 0;
  font-size: 0;
  text-align: center;
  list-style: none;
  list-style-type: none;

  @include e(item) {
    display: inline-block;
    width: 100%;
    padding: 20px;
    text-align: left;
    vertical-align: top;

    @include gridle_state (md) {
      width: 33%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-transform: uppercase;
    }

    .course-date {
      font-family: $font-family-sans-serif;
      font-size: 1.5rem;
      font-weight: 500;
    }

    p,
    li {
      font-size: $font-size-lg;
      margin-bottom: 0;

      @include gridle_state (tb) {
        font-size: $font-size-xlg;
      }
    }
  }

  @include e(inner) {
    padding: 1rem;
    border: 3px solid $brand-primary;

    @include gridle_state (md) {
      padding: 2rem;
    }

    @include m(voucher) {
      background: lighten($brand-primary, 6%);
    }
  }
}
