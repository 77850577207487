// flexbox

@include b(flex-wrapper) {
  display: flex;

  // flex-direction: column;
  // flex-direction: row;

  // flex-wrap: no-wrap;
  // flex-wrap: wrap;
  // flex-wrap: wrap-reverse;

  // shorthand for flex-direction and flex-wrap:
  // flex-flow: row wrap;

  // justify-content: flex-start;
  // justify-content: flex-end;
  // justify-content: center;
  // justify-content: space-between;
  // justify-content: space-around;

  // align-items: flex-start;
  // aign-items: flex-end;
  // align-items: center;
  // aign-items: baseline;
  // align-items: stretch;

}