// colors

$black:                       #000 !default;
$grey-dark:                   #373a3c !default;
$grey:                        #dad9d6 !default;
$grey-light:                  #818a91 !default;
$grey-lighter:                #eceeef !default;
$grey-lightest:               #f7f7f9 !default;
$white:                       #fff !default;

$brand-primary:               #daab9c !default;
$brand-secondary:             #615d59 !default;
$brand-tertiary:              #f6bd9d !default;

$brand-success:               #5cb85c !default;
$brand-info:                  #5bc0de !default;
$brand-warning:               #f0ad4e !default;
$brand-danger:                #d9534f !default;

$social-facebook:         		#3b5998 !default;
$social-twitter:          		#5bc0de !default;
$social-google:           		#df4a32 !default;
$social-linkedin:         		#007bb6 !default;
$social-youtube:          		#b31217 !default;
$social-instagram:        		#c32aa3 !default;
$social-pinterest:        		#cb2027 !default;
$social-snapchat:         		#fffc00 !default;
$social-skype:            		#00aff0 !default;
$social-rss:                  #ee802f !default;

.white {color: $white;}
