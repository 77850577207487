// wordpress classes

.alignnone {margin: 5px 20px 20px 0;}
.aligncenter, div.aligncenter {display: block; margin: 5px auto 5px auto;}
.alignright {float:right; margin: 5px 0 20px 20px;}
.alignleft {float: left; margin: 5px 20px 20px 0;}
.aligncenter {display: block; margin: 5px auto 5px auto;}
a img.alignright {float: right; margin: 5px 0 20px 20px;}
a img.alignnone {margin: 5px 20px 20px 0;}
a img.alignleft {float: left; margin: 5px 20px 20px 0;}
a img.aligncenter {display: block; margin-left: auto; margin-right: auto}
.wp-caption {padding: 5px 3px 10px; max-width: 96%; background: #fff; border: 1px solid #f0f0f0; text-align: center;}
.wp-caption.alignnone {margin: 5px 20px 20px 0;}
.wp-caption.alignleft {margin: 5px 20px 20px 0;}
.wp-caption.alignright {margin: 5px 0 20px 20px;}
.wp-caption img {margin: 0; padding: 0; width: auto; height: auto; max-width: 98.5%; border: 0 none;}
.wp-caption p.wp-caption-text {margin: 0; padding: 0 4px 5px; font-size: 11px; line-height: 17px;}

.post {}

.entry-content {}
.entry-content a {}
.entry-content a:hover {}

#meta {}
.postmetadata {}

.navgation {}
.next-posts {}
.prev-posts {}

ol.commentlist {list-style: none;}
ol.commentlist li {}
ol.commentlist li.alt {}
ol.commentlist li.bypostauthor {}
ol.commentlist li.byuser {}
ol.commentlist li.comment-author-admin {}
ol.commentlist li.comment {border-bottom: 1px dotted #666; padding: 10px;}
ol.commentlist li.comment div.comment-author {}
ol.commentlist li.comment div.vcard {}
ol.commentlist li.comment div.vcard cite.fn {font-style: normal;}
ol.commentlist li.comment div.vcard cite.fn a.url {}
ol.commentlist li.comment div.vcard img.avatar {float:right; margin: 0 0 10px 10px;}
ol.commentlist li.comment div.vcard img.avatar-32 {}
ol.commentlist li.comment div.vcard img.photo {}
ol.commentlist li.comment div.vcard span.says {}
ol.commentlist li.comment div.commentmetadata {}
ol.commentlist li.comment div.comment-meta {font-size: 10px;}
ol.commentlist li.comment div.comment-meta a {color: #ccc;}
ol.commentlist li.comment p {}
ol.commentlist li.comment ul {}
ol.commentlist li.comment div.reply {font-size: 11px;}
ol.commentlist li.comment div.reply a {font-weight: bold;}
ol.commentlist li.comment ul.children {list-style: none; margin: 10px 0 0;}
ol.commentlist li.comment ul.children li {}
ol.commentlist li.comment ul.children li.alt {}
ol.commentlist li.comment ul.children li.bypostauthor {}
ol.commentlist li.comment ul.children li.byuser {}
ol.commentlist li.comment ul.children li.comment {}
ol.commentlist li.comment ul.children li.comment-author-admin {}
ol.commentlist li.comment ul.children li.depth-2 {border-left: 5px solid #555; margin: 0 0 10px 10px;}
ol.commentlist li.comment ul.children li.depth-3 {border-left: 5px solid #999; margin: 0 0 10px 10px;}
ol.commentlist li.comment ul.children li.depth-4 {border-left: 5px solid #bbb; margin: 0 0 10px 10px;}
ol.commentlist li.comment ul.children li.depth-5 {}
ol.commentlist li.comment ul.children li.odd {}
ol.commentlist li.even {background: #fff;}
ol.commentlist li.odd {background: #f6f6f6;}
ol.commentlist li.parent {border-left: 5px solid #111;}
ol.commentlist li.thread-alt {}
ol.commentlist li.thread-even {}
ol.commentlist li.thread-odd {}

.gallery:after {content:"";display:table;clear:both;}

img.aligncenter, div.aligncenter, figure.aligncenter, img.wp-post-image {display:block; margin:$font-size-base auto;}
img.alignright, div.alignright, figure.alignright {float:right; margin:1em 0 1em 2em; margin:$font-size-base; margin-right: 0;}
img.alignleft, div.alignleft, figure.alignleft, img.wp-post-image.attachment-thumb {float:left; margin:$font-size-base; margin-left: 0;}
figure {max-width: 100%; height: auto; margin:$font-size-base 0;}
p img.alignright, p img.alignleft {margin-top:0;}

.gallery {text-align: center;}

.gallery figure img {max-width:100%; height:auto; margin:0 auto; display:block;}

.gallery figure {display: inline-block; margin:0 2% $font-size-base 0;}

.gallery.gallery-columns-1 figure {width:100%; margin:0 0 $font-size-base 0; float:none;}

.gallery.gallery-columns-3 figure {width:32%;}
.gallery.gallery-columns-3 figure:nth-of-type(3n+3) {margin-right:0;}
.gallery.gallery-columns-3 figure:nth-of-type(3n+4) {clear:left;}

.gallery.gallery-columns-2 figure {width:49%;}
.gallery.gallery-columns-2 figure:nth-of-type(even) {margin-right:0;}
.gallery.gallery-columns-2 figure:nth-of-type(odd) {clear:left;}

.gallery.gallery-columns-4 figure {width:23.25%;}
.gallery.gallery-columns-4 figure:nth-of-type(4n+4) {margin-right:0;}
.gallery.gallery-columns-4 figure:nth-of-type(4n+5) {clear:left;}

.gallery.gallery-columns-5 figure {width:18%;}
.gallery.gallery-columns-5 figure:nth-of-type(5n+5) {margin-right:0;}
.gallery.gallery-columns-5 figure:nth-of-type(5n+6) {clear:left;}

.gallery.gallery-columns-6 figure {width:14.2%;}
.gallery.gallery-columns-6 figure:nth-of-type(6n+6) {margin-right:0;}
.gallery.gallery-columns-6 figure:nth-of-type(6n+7) {clear:left;}

.gallery.gallery-columns-7 figure {width:12%;}
.gallery.gallery-columns-7 figure:nth-of-type(7n+7) {margin-right:0;}
.gallery.gallery-columns-7 figure:nth-of-type(7n+8) {clear:left;}

.gallery.gallery-columns-8 figure {width:10.2%;}
.gallery.gallery-columns-8 figure:nth-of-type(8n+8) {margin-right:0;}
.gallery.gallery-columns-8 figure:nth-of-type(8n+9) {clear:left;}

.gallery.gallery-columns-9 figure {width:8.85%;}
.gallery.gallery-columns-9 figure:nth-of-type(9n+9) {margin-right:0;}
.gallery.gallery-columns-9 figure:nth-of-type(9n+10) {clear:left;}

.gallery figcaption {
  padding: .5rem 0;
  text-align: center;
  font-size: $font-size-sm;
}


@media (max-width:767px) {
  img.alignright,
  div.alignright,
  figure.alignright,

  img.alignleft,
  div.alignleft,
  figure.alignleft,

  img.wp-post-image.attachment-thumb {
    display:block;
    margin:$font-size-base auto;
    float:none;
  }

  .gallery.gallery-columns-4 figure,
  .gallery.gallery-columns-5 figure,
  .gallery.gallery-columns-6 figure,
  .gallery.gallery-columns-7 figure,
  .gallery.gallery-columns-8 figure,
  .gallery.gallery-columns-9 figure {
    width:100%;
    margin:0 0 $font-size-base 0;
    float:none;
  }
}

