@include b(header) {
  @include gridle (22 prefix 1 suffix 1 md 24 prefix 0 suffix 0);
  position: relative;
}

@include b(header-contact) {
  float: left;

  p {
    font-family: $font-family-sans-serif;
    font-size: .875rem;
    font-weight: $font-weight-bold;
    line-height: 1.5rem;

    @include gridle_state (md) {
      font-size: 1.125rem;
      line-height: 1.875rem;
    }
  }

  .social-menu {
    margin-bottom: .625rem;
  }

  .social-menu__item {
    margin-right: .75rem;

    a {
      width: 24px;
      height: 24px;
    }
  }

  a {
    color: $text-color;
  }
}
