// Tables

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            8px !default;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:  5px !default;

//** Default background color used for all tables.
$table-bg:                      transparent !default;
//** Background color used for `.table-striped`.
$table-bg-accent:               $grey-lightest !default;
//** Background color used for `.table-hover`.
$table-bg-hover:                #f5f5f5 !default;
$table-bg-active:               $table-bg-hover !default;

//** Border color for table and cell borders.
$table-border-color:            #ddd !default;
