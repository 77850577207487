.gallery-row {
  padding-bottom: 40px;

  &:last-child {
    padding-bottom: 0;
  }
}

@include b(gallery) {
  display: block;
  padding: 0;
  margin: 0 auto;
  font-size: 0;
  text-align: center;

  @include e(item) {
    list-style: none;
    list-style-type: none;

    @include m(single-item) {
      @include gridle (24 prefix 0 suffix 0);
    }

    @include m(two-items) {
      @include gridle (12 prefix 0 suffix 0);
    }

    @include m(three-items) {
      @include gridle (8 prefix 0 suffix 0);
    }

    @include m(four-items) {
      @include gridle (6 prefix 0 suffix 0);
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
