#gform_9 {

  .gfield_product_9_5 {

    .gfield_label {
      display: block;
      margin-top: 3rem;
      margin-bottom: 1rem;
      font-size: 24px;
    }

    .gfield_radio {
      line-height: 2;

      label {
        font-size: 20px;
      }
    }
  }

  .gfield_price {
    margin-bottom: 2rem;
    font-size: 24px;
    font-weight: bold;

    label {
      font-size: 20px;
    }
  }
}
