// title.svg

.title-clr {
  fill: $brand-secondary;
}

// title

@include b(title) {
  @include gridle (22 prefix 1 suffix 1 md 24 prefix 0 suffix 0);
  display: block;
  transition: width .8s, height .8s;

  @include m(front-page) {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}
