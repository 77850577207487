// section

@include b(section) {
  @extend %clearfix;
  @include gridle (22 prefix 1 suffix 1 md 24 prefix 0 suffix 0);

  position: relative;

  @include e(image) {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;

    @include gridle_state (md) {
      width: 58%;
    }

    @include m(left) {}

    @include m(right) {
      float: none;

      @include gridle_state (md) {
        float: right;
      }
    }
  }

  @include e(content-wrap) {
    position: relative;
    z-index: 2;
    width: 100%;

    @include gridle_state (md) {
      width: 44%;
    }

    @include m(left) {}

    @include m(right) {
      float: right;
      margin-top: 0;

      @include gridle_state (md) {
        margin-top: -422px;
      }

      @include gridle_state (lg) {
        margin-top: -509px;
      }

      @include gridle_state (xlg) {
        margin-top: -609px;
      }
    }
  }

  @include e(title) {
    padding-left: 2.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-transform: uppercase;

    @include m(right) {
      padding-left: 2.5rem;

      @include gridle_state (md) {
        padding-left: calc(4rem + 3px);
      }
    }

    @include m(blog) {
      padding-right: 4.5rem;
      margin-bottom: 0;
    }
  }

  @include e(content) {
    position: relative;
    // padding: 0 2.5rem;
    padding: 0;

    @include m(border) {
      padding: 2.5rem;
      border: 3px solid $brand-primary;
    }

    @include m(right) {
      padding-left: 2.5rem;

      @include gridle_state (md) {
        padding-left: 4rem;
      }
    }

    @include m(blog) {
      padding-top: 2.5rem;

      h2 {
        position: absolute;
        top: calc(2.5rem + 50px);
        left: -1.25rem;
        display: inline-block;
        text-align: right;
        text-transform: uppercase;

        -webkit-transform: rotate(-90deg);
        // Firefox
        -moz-transform: rotate(-90deg);
        // IE
        -ms-transform: rotate(-90deg);
        // Opera
        -o-transform: rotate(-90deg);
        // Internet Explorer
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

        @include gridle_state (tb) {
          top: calc(2.5rem + 70px);
          left: 3px;
        }
      }
    }
  }
}
