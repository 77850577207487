@include b(content-page) {
  @include gridle (22 prefix 1 suffix 1 md 24 prefix 0 suffix 0);
  text-align: center;

  @include e(content) {
    position: relative;
    padding: 0;

    @include gridle_state (md) {
      padding: 0 2.5rem;
    }
  }

  @include m(narrow) {
    @include gridle (22 prefix 1 suffix 1 md 18 prefix 3 suffix 3);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
  }

  p {

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include b(content-page-header) {
  margin-bottom: 1.5rem;
  text-align: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
  }
}

@include b(border) {
  padding: 1.5rem;
  border: 3px solid $brand-primary;

  @include m(form) {
    padding: 3rem 1.5rem;

    @include gridle_state (tb) {
      padding: 3rem;
    }

    @include gridle_state (md) {
      padding: 3rem 5rem;
    }

    form {
      margin-bottom: 0;

      .gform_footer {
        text-align: center;

        .gform_button {
          padding: 1.25rem 2.5rem;
        }
      }
    }
  }
}
